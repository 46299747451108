import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { END } from 'redux-saga';
import PageLayout from '../../components/layouts/Main/PageLayout';
import { allJobsRequest } from '../../redux/actions/jobs';
import SEO from '../../components/molecules/Seo';
import { wrapper } from '../../redux/store';
import { JobsResultsList } from '../../components/organisms/jobsResultsList';
import { useAllCompaniesWithoutRequest } from '../../hooks/useAllCompanies';
import { getSSRProtocolAndHost } from '../../helpers/ssrHelpers';
import JobListFilters from '../../components/organisms/jobListFilters';
import useCustomFilters from '../../hooks/useCustomFilters';
import useJobFunctions from '../../hooks/useJobFunctions';
import {
  organizationsStringSelectorFilter,
  showJobAlertsSelector,
  talentNetworkSelector,
} from '../../redux/selectors/network';
import { NetworkSchema } from '../../schema/network';
import { ProtocolSchema } from '../../schema/protocol';
import { decryptQuery } from '../../hooks/useEncryptedRouter';
import { useCompanyFacets } from '../../hooks/useCompanyFacets';
import { JOB_SENIORITY_VALUES } from '../../constants/index';

const Jobs = ({ network, host, protocol }) => {
  const { jobFunctions, initialized: jobFunctionsInitialized, isLoadingJobFunctions } = useJobFunctions({ network });
  const { visibleCustomFilters, isLoading: isLoadingCustomFilters } = useCustomFilters({ network });
  const { list: allCompaniesList, isLoading: isAllCompaniesLoading } = useAllCompaniesWithoutRequest({ network });
  const { industryTags, headCounts, stages, loading: isLoading } = useCompanyFacets({ network });
  const companyFilterName = organizationsStringSelectorFilter(false, network);
  const { jobBoardFilters } = network;
  const { jobFunctionFilter, stageFilter, seniorityFilter, industryTagsFilter, companySizeFilter, compensationFilter } =
    jobBoardFilters;
  const hideJobFunctionFilter = !jobFunctionFilter;
  const hideStageFilters = !stageFilter;
  const hideSeniorityFilter = !seniorityFilter;
  const hideCompensationFilter = !compensationFilter;
  const hideIndustryTagsFilter = !industryTagsFilter;
  const hideCompanySizeFilter = !companySizeFilter;
  const hasTalentNetwork = talentNetworkSelector(network);
  const showJobAlerts = showJobAlertsSelector(network);
  const areFiltersLoading = () => {
    if (!hideJobFunctionFilter) {
      return isLoadingJobFunctions || isLoadingCustomFilters || isAllCompaniesLoading || isLoading;
    }
    return isLoadingCustomFilters || isAllCompaniesLoading || isLoading;
  };

  const filterOptions = useMemo(() => {
    let order = 1;
    const result = [];

    if (!hideJobFunctionFilter && jobFunctions.length > 0) {
      result.push({
        order,
        queryParam: 'job_functions',
        name: 'Job function',
        options: jobFunctions,
        canFilterResults: jobFunctions.length > 10,
      });
    }

    order += !hideJobFunctionFilter ? 1 : 0;

    if (!hideSeniorityFilter) {
      order += 1;

      result.push({
        order,
        queryParam: 'seniority',
        name: 'Seniority',
        options: Object.entries(JOB_SENIORITY_VALUES).map(([key, value]) => ({
          name: value,
          value: key,
        })),
        sort: false,
        canFilterResults: true,
      });
    }

    if (!hideCompensationFilter) {
      order += 1;

      result.push({
        order,
        queryParam: 'compensation',
        name: 'Salary',
      });
    }

    if (industryTags?.length && !hideIndustryTagsFilter) {
      order += 1;

      result.push({
        order,
        queryParam: 'organization.industry_tags',
        name: 'Industry',
        options: industryTags,
        canFilterResults: industryTags.length > 10,
      });
    }

    if (stages?.length && !hideStageFilters) {
      order += 1;

      result.push({
        order,
        queryParam: 'organization.stage',
        name: 'Company stage',
        options: stages,
        sort: false,
        canFilterResults: stages.length > 10,
      });
    }
    if (headCounts?.length && !hideCompanySizeFilter) {
      order += 1;

      result.push({
        order,
        queryParam: 'organization.head_count',
        name: 'Company size',
        options: headCounts,
        sort: false,
        canFilterResults: headCounts.length > 10,
      });
    }

    if (visibleCustomFilters?.length) {
      result.push(
        ...visibleCustomFilters.map((item) => {
          order += 1;

          return {
            ...item,
            order,
            sort: false,
            canFilterResults: item?.options?.length > 10,
          };
        }),
      );
    }

    order += 1;
    result.push({
      order,
      queryParam: 'organization.id',
      name: companyFilterName,
      options: allCompaniesList,
      canFilterResults: allCompaniesList.length > 10,
    });

    return result;
  }, [
    allCompaniesList,
    companyFilterName,
    visibleCustomFilters,
    headCounts,
    hideCompanySizeFilter,
    hideIndustryTagsFilter,
    hideJobFunctionFilter,
    hideSeniorityFilter,
    hideStageFilters,
    hideCompensationFilter,
    industryTags,
    jobFunctions,
    stages,
  ]);

  return (
    <>
      <SEO network={network} title="Jobs" host={host} protocol={protocol} />
      <JobListFilters
        isLoading={areFiltersLoading()}
        network={network}
        searchPlaceholder="Job title, company or keyword"
        filterOptions={filterOptions}
      />
      <JobsResultsList
        showJobAlertsButton={showJobAlerts}
        network={network}
        canDiscard={hasTalentNetwork}
        canFavorite={hasTalentNetwork}
        jobFunctions={jobFunctionsInitialized ? jobFunctions : null}
      />
    </>
  );
};

Jobs.propTypes = {
  network: NetworkSchema.isRequired,
  protocol: ProtocolSchema.isRequired,
  host: PropTypes.string.isRequired,
};

Jobs.Layout = PageLayout;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (ctx) => {
  const { protocol, host } = getSSRProtocolAndHost(ctx);
  const baseQuery = ctx.query;
  const query = decryptQuery(baseQuery);
  const { req } = ctx;
  const { network } = req;

  if (network?.id) {
    store.dispatch(
      allJobsRequest({
        network,
        query: { ...query, page: 1 },
      }),
    );
  }

  store.dispatch(END);
  await store.sagaTask.toPromise();
  return {
    props: { protocol, host },
  };
});

export default Jobs;
